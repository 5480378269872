// Testimonial.js
import React from "react";

const Testimonial = ({ content, name, profilePhoto }) => {
  return (
    <div className="max-w-md mx-auto mb-8">
      <div className="bg-white p-6 rounded-lg shadow-md flex flex-col relative">
        <img
          src={profilePhoto}
          alt="Profile"
          className="mx-auto w-36 h-36 rounded-full mb-14 drop-shadow-2xl"
        />
        <p className="text-gray-500 mb-10 font-bold italic ">{content}</p>
        <div className="absolute bottom-0 right-0 mb-4">
          <p className="text-gray-800 text-sm font-bold pr-9">{name}</p>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
