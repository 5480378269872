// AboutUs.js
import React from "react";
import ProfilePhoto from "./li.jpg"; // Update with your actual profile photo

const AboutUs = () => {
  const article = [
    {
      tittle: "10 Social Media Strategies for Business Growth",
      body: "In this article, we delve into effective social media strategies to boost your business growth. From content creation to engagement, we've got you covered.",
    },

    {
      tittle:
        "The Power of Visuals: Creating Engaging Graphics for Social Media",
      body: "Discover the impact of visual content on social media platforms. Learn how to create eye-catching graphics that captivate your audience and convey your brand message effectively.",
    },
    {
      tittle:
        " The Power of Visuals: Creating Engaging Graphics for Social Media",
      body: " Discover the impact of visual content on social media platforms. Learn how to create eye-catching graphics that captivate youraudience and convey your brand message effectively.",
    },
  ];

  return (
    <div id="About us">
      <div className="container mx-auto py-10">
        <section className="text-center">
          <img
            src={ProfilePhoto}
            alt="Social Media Manager"
            className="mx-auto w-64 h-64 rounded-full mb-6 mt-0 drop-shadow-2xl"
          />
          <h2 className="text-black text-4xl font-bold mb-7 mt-10">
            Liya Mathyas Betre
          </h2>
          <p className="text-gray-800 italic text-lg font-semibold leading-8 pl-4 pr-4">
            I am a highly motivated and results-driven Social Media Manager with
            a proven track record of creating engaging content and implementing
            successful social media strategies. My educational journey includes
            obtaining a High School Diploma from Nazareth School, where I
            developed a solid academic foundation from September 1, 2014, to
            June 30, 2018. Continuing my education, I pursued Higher Education
            at Tikur Anbessa Medical School of AAU (Addis Ababa University) from
            September 1, 2018, to June 30, 2019. Currently, I am dedicated to
            continuous learning and personal development at St. Mary’s
            University since September 1, 2019. In my professional career, I
            have been actively contributing as a Marketing and Sales Expert at
            K.A.M PLC since November 1, 2020. This role has provided me with
            valuable insights into marketing strategies and sales dynamics,
            which I seamlessly integrate into my social media management
            strategies. Furthermore, I am engaged in tutoring at Haleta Tutors
            since November 14, 2020, and have experience transcribing legal
            documents with a keen eye for detail and accuracy since April 2020.
            My computer skills include proficiency in Microsoft Excel, Word,
            PowerPoint, Publisher, and Access. As a Social Media Manager, I have
            successfully implemented comprehensive social media campaigns,
            increased brand awareness, and fostered meaningful connections with
            the audience. I possess strong personal skills, including teamwork,
            managerial, and organizational abilities, which have been honed
            through various school projects, internships, and my current role as
            a social media professional. I am excited about the opportunity to
            bring my social media expertise, educational background, and diverse
            experiences to contribute positively to your organization's success.
            Thank you for considering my profile, and I look forward to the
            possibility of discussing how I can enhance your social media
            presence and achieve your business objectives.
          </p>
        </section>

            </div>
    </div>
  );
};

export default AboutUs;
