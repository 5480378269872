import React from "react";

const Services = () => {
  return (
    <div id="services">
      <div className="container mx-auto py-6 md:py-10 pl-4 pr-4 md:pl-14 md:pr-14">
        <h3 className="text-2xl md:text-3xl font-semibold mb-8 text-center text-black">
          Services
        </h3>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* Graphics Design */}
          <div className="bg-indigo-200 p-4 md:p-6 rounded-lg text-black text-justify">
            <h3 className="text-xl md:text-2xl font-semibold mb-2 md:mb-4">
              Graphics Design
            </h3>
            <p className="text-gray-800 mb-2 md:mb-4">
              As a dedicated graphics designer, I provide a range of creative
              services aimed at enhancing your brand's visual identity. With a
              keen eye for design and a passion for creativity, I am committed
              to delivering stunning graphics that resonate with your audience.
            </p>
            <h2 className="text-lg md:text-xl font-bold mb-2 md:mb-4">
              Key Services:
            </h2>
            <ul className="list-disc list-inside mb-2 md:mb-4">
              <li>
                Captivating Visuals: Transform your social media channels with
                visually appealing graphics for various platforms.
              </li>
              <li>
                Consistent Branding: Design graphics that align seamlessly with
                your brand identity, ensuring a cohesive online image.
              </li>
              <li>
                Tailored Campaign Graphics: Create graphics for product
                launches, promotions, and brand awareness campaigns.
              </li>
              <li>
                Infographics and Data Visualization: Simplify complex
                information with visually striking infographics.
              </li>
            </ul>
            <h2 className="text-lg md:text-xl font-bold mb-2 md:mb-4">
              Why Choose My Services:
            </h2>
            <ul className="list-disc list-inside mb-2 md:mb-4">
              <li>
                Strategic Creativity: Aligning graphics strategically with your
                social media goals and audience preferences.
              </li>
              <li>
                Responsive Design: Optimizing all graphics for different
                platforms, devices, and screen sizes.
              </li>
              <li>
                Adaptability: Staying updated with industry trends to reflect
                the latest design styles and platform updates.
              </li>
            </ul>
            <p className="text-gray-800">
              As your graphics designer, I am dedicated to transforming your
              brand's online narrative. Let's collaborate to turn your social
              media channels into a visually compelling story that resonates
              with your audience.
            </p>
          </div>

          {/* Social Media Management */}
          <div className="bg-yellow-200 p-4 md:p-6 rounded-lg text-black text-justify">
            <h3 className="text-xl md:text-2xl font-semibold mb-2 md:mb-4">
              Social Media Management
            </h3>
            <p className="text-gray-800 mb-2 md:mb-4">
              As a seasoned social media marketing expert, I offer comprehensive
              services to elevate your brand's online presence and engagement.
              With a data-driven approach and a creative mindset, I am dedicated
              to delivering impactful strategies that drive results and foster
              meaningful connections with your audience.
            </p>
            <h2 className="text-lg md:text-xl font-bold mb-2 md:mb-4">
              Key Services:
            </h2>
            <ul className="list-disc list-inside mb-2 md:mb-4">
              <li>
                Strategic Social Media Planning: Develop customized social media
                strategies aligned with your business goals.
              </li>
              <li>
                Content Creation and Curation: Craft compelling and shareable
                content tailored for each social media platform.
              </li>
              <li>
                Community Engagement: Foster meaningful interactions with your
                audience through comments, messages, and discussions.
              </li>
              <li>
                Analytics and Reporting: Utilize data analytics to track
                performance, measure ROI, and refine strategies for continuous
                improvement.
              </li>
            </ul>
            <h2 className="text-lg md:text-xl font-bold mb-2 md:mb-4">
              Why Choose My Services:
            </h2>
            <ul className="list-disc list-inside mb-2 md:mb-4">
              <li>
                Targeted Audience Reach: Implement strategies to connect with
                your target audience and expand your online reach.
              </li>
              <li>
                Brand Consistency: Ensure a consistent and cohesive brand
                identity across all social media channels.
              </li>
              <li>
                Campaign Optimization: Regularly optimize marketing campaigns
                based on performance metrics and audience feedback.
              </li>
            </ul>
            <p className="text-gray-800">
              Partner with me to harness the power of social media marketing and
              propel your brand towards sustained growth and recognition. Let's
              work together to create a compelling online narrative that
              resonates with your audience and drives success.
            </p>
          </div>

          {/* Content Creation */}
          <div className="bg-pink-200 p-4 md:p-6 rounded-lg text-black text-justify">
            <h3 className="text-xl md:text-2xl font-semibold mb-2 md:mb-4 md:w[50%]">
              Content Creation
            </h3>
            <p className="text-gray-800 mb-2 md:mb-4">
              At Your Company, the emphasis is on delivering top-notch content
              creation services that elevate your brand's story and captivate
              your audience. I specialize in producing high-quality, engaging
              content tailored to your unique brand identity and marketing
              goals.
            </p>
            <h2 className="text-lg md:text-xl font-bold mb-2 md:mb-4">
              Key Services:
            </h2>
            <ul className="list-disc list-inside mb-2 md:mb-4">
              <li>
                Strategic Content Planning: Develop customized content
                strategies aligned with your brand and marketing objectives.
              </li>
              <li>
                Copywriting and Editing: Craft compelling and persuasive copy
                for various platforms, ensuring consistency and brand voice.
              </li>
              <li>
                Visual Content Creation: Produce eye-catching graphics, images,
                and videos that resonate with your target audience.
              </li>
              <li>
                Blog and Article Writing: Create informative and engaging blog
                posts and articles to establish thought leadership.
              </li>
            </ul>
            <h2 className="text-lg md:text-xl font-bold mb-2 md:mb-4">
              Why Choose Our Content Creation Services:
            </h2>
            <ul className="list-disc list-inside mb-2 md:mb-4">
              <li>
                Creative Excellence: I am dedicated to delivering creative
                excellence in every piece of content I create.
              </li>
              <li>
                Brand Consistency: Maintain a cohesive brand identity across all
                content, ensuring a unified and recognizable image.
              </li>
              <li>
                Targeted Messaging: Tailor content to resonate with your target
                audience, fostering meaningful connections.
              </li>
            </ul>
            <p className="text-gray-800">
              Collaborate with me to bring your brand's story to life through
              compelling and impactful content. From engaging social media posts
              to informative blog articles, I am committed to helping your brand
              stand out in the digital landscape.
            </p>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
