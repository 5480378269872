import "./App.css";
import Navbar from "./components/Navbar";
import AboutUs from "./components/AboutUs";
import Services from "./components/Services";
import ContactUs from "./components/Contactus";
import Footer from "./components/Footer";
import ContactForm from "./components/Contactform";
import YourComponent from "./components/YourComponent";
function App() {
  return (
    <div className="App">
      <Navbar />
      <AboutUs />
      <Services />
      <YourComponent />
      {/* <MainPage /> */}

      <div className="flex flex-col md:flex-col items-center bg-gray-200 p-2  mb-24 rounded-md">
        <ContactUs />
        <ContactForm />
      </div>
      <Footer />
    </div>
  );
}

export default App;
