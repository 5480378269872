import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faInstagram,
  faTelegram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

const ContactUs = () => {
  return (
    <div id="Contact Us">
      <div className="py-10 w-full md:w-[100%] flex flex-col items-center  rounded-lg p-6">
        <h2 className="text-3xl font-bold mb-6 text-center bg-yellow-00 p-2 rounded-md">
          Contact Us
        </h2>

        <div className="flex flex-col items-center">
          {/* Phone Number */}
          <div className="mb-4 w-full md:w-[50%]">
            <a
              href="tel:+251976758297"
              className="flex items-center bg-gray-200 text-gray-800 px-4 py-2 rounded hover:bg-purple-900 hover:text-white hover:opacity-80 hover:shadow-lg w-full md:w-[90%] transition duration-300 ease-in-out"
            >
              <FontAwesomeIcon icon={faPhone} className="text-lg mr-2" /> Call
              Us: +251-9-76-75-82-97
            </a>
          </div>

          {/* Email */}
          <div className="mb-4 w-full md:w-[50%]">
            <a
              href="mailto:liyamathyas20@gmail.com"
              className="flex items-center bg-gray-200 text-gray-800 px-4 py-2 rounded hover:bg-green-900 hover:text-white hover:opacity-80 hover:shadow-lg transition duration-300 ease-in-out w-full md:w-[90%]"
            >
              <FontAwesomeIcon icon={faEnvelope} className="text-lg mr-2" />{" "}
              Email Us
            </a>
          </div>

          {/* Social Media Links */}
          <div className="flex flex-col space-y-4 w-full md:w-[50%] md:flex-row md:space-x-4">
            <a
              href="https://instagram.com/liyamathyas?utm_source=qr"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center bg-gray-200 text-gray-800 px-4 py-2 rounded hover:bg-teal-900 hover:text-white hover:opacity-80 hover:shadow-lg w-full md:w-[100%] transition duration-300 ease-in-out"
            >
              <FontAwesomeIcon icon={faInstagram} className="text-lg mr-2" />{" "}
              Instagram
            </a>
            <a
              href="https://t.me/liyambetre"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center bg-gray-200 text-gray-800 px-4 py-2 rounded hover:bg-blue-600 hover:text-white hover:opacity-80 hover:shadow-lg w-full md:w-[48%] transition duration-300 ease-in-out"
            >
              <FontAwesomeIcon icon={faTelegram} className="text-lg mr-2" />{" "}
              Telegram
            </a>
            <a
              href="https://www.linkedin.com/in/your-linkedin-username"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center bg-gray-200 text-gray-800 px-4 py-2 rounded hover:bg-indigo-900 hover:text-white hover:opacity-80 hover:shadow-lg w-full md:w-[48%] transition duration-300 ease-in-out"
            >
              <FontAwesomeIcon icon={faLinkedin} className="text-lg mr-2" />{" "}
              LinkedIn
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
