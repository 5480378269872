import React from "react";
import Testimonial from "./Testimonial";
import ProfilePhoto1 from "./Images/babis.jpg"; // Import the image
import ProfilePhoto2 from "./Images/royaltes.jpg"; // Import the image

const YourComponent = () => {
  const testimonials = [
    {
      content: (
        <>
          It is my pleasure to provide a testimonial for Liya, who served as a
          valued member of our marketing team.
          During her time with us, Liya showcased an exemplary level of
          professionalism, dedication, and a keen eye for detail. Her commitment
          to delivering outstanding results was evident in every project she
          undertook. Liya's ability to adapt to new challenges, coupled with her
          strong work ethic, made her a standout contributor. Beyond her
          impressive work skills, Liya is characterized by her honesty,
          ambition, and exceptional relationship-building abilities. Her
          positive attitude and collaborative approach significantly contributed
          to the positive dynamics within our team. I am confident that Liya
          will continue to thrive in her future endeavors, and I wholeheartedly
          recommend her for any professional role she pursues.
          <br />
          Sincerely,
          <br />
        </>
      ),
      name: "Royal Ceramics",
      profilePhoto: ProfilePhoto2,
    },
    {
      content:
        "The expertise and dedication of liya is unmatched. I highly recommend her services to anyone looking for quality.",
      name: "Babis",
      profilePhoto: ProfilePhoto1,
    },
  ];

  return (
    <div id="testimonials">
      <div className="flex flex-col md:flex-col items-center p-2 mx-4 md:mx-48 mb-24 rounded-md">
        {/* Heading for Testimonials */}
        <h3 className="text-black text-4xl mb-10 mt-14">Testimonials</h3>

        {/* Testimonials */}
        <div className="flex flex-col md:flex-row md:flex-wrap md:justify-between w-full">
          {testimonials.map((testimonial, index) => (
            <Testimonial
              key={index}
              content={testimonial.content}
              name={testimonial.name}
              profilePhoto={testimonial.profilePhoto}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default YourComponent;
